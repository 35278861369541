<template>
  <!--我的任务-->
  <div class="my-enlis">
    <div class="ybox-mod enbuss-mod">
      <div class="ybox-title">
        <div class="pull-left">
          <en-icon v-if="flag" name="fanhui" size="small" style="vertical-align:middle;cursor: pointer;margin-left: 0px;" @click.native="callback"> </en-icon>
          <span style="margin-right:10px;">我的任务</span>
        </div>
        <div class="pull-right">
          <el-dropdown placement="bottom-start" style="height:20px;">
            <span class="el-dropdown-link">
              <en-icon name="tianjia-kuaijiecaozuo" size="small" style="color:#3e90fe;"></en-icon>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="addTask()">
                新增任务
              </el-dropdown-item>
              <el-dropdown-item @click.native="taskList()">
                任务列表
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div class="ybox-content" v-en-loading="loading">
        <div class="enlis enlis-buss" v-if="!noData" id="mytask-enlis">
          <div v-for="(item, k) in noticeList" :key="k" class="mod" @click="modClick(item, k)">
            <div class="col col-icon">
              <p>
                <span class="iconfont">
                  <en-icon name="renwu-xianxing" size="small"></en-icon>
                </span>
              </p>
            </div>
            <div class="pull-left">
              <div class="col col-num">
                <p>{{ item.code || "" }}</p>
              </div>
              <div class="col col-tit">
                <p>{{ item.name || "" }}</p>
              </div>
            </div>
            <div class="pull-right">
              <div class="col col-time">
                <p>{{ getTaskTime(item.startTime, item.endTime, item.finishTime, item.progress_progressStatus) }}</p>
              </div>
              <div class="col col-operate">
                <el-tag
                  style="height: 24px;line-height: 24px;border:none;border-radius:2px;"
                  :style="'color:' + planState[item.progress_progressStatus][1] + ';' + 'background:' + planState[item.progress_progressStatus][2] + ';'"
                >
                  {{ planState[item.progress_progressStatus][0] || "" }}
                </el-tag>
              </div>
            </div>
          </div>
        </div>
        <en-result v-if="noData" type="NoData"></en-result>
        <!-- 加载更多 -->
        <viewAddMore v-if="myNotice.hasNext" :total="myNotice.total" :pageNo="pageNo" :pageSize="pageSize" @addMore="addMore('/myTask')"></viewAddMore>
      </div>
    </div>
    <viewBacktop class="back-top" elementId="mytask-enlis"></viewBacktop>
  </div>
</template>

<script>
import { otherEntranceRoute, newEntranceRoute } from "@/components/businessDetail/helper.js";
import { enService } from "@/api/en/index";
import en from "@/mixins/en/en"; // 公共方法
import viewBacktop from "./readComm/viewBacktop";
import viewAddMore from "./readComm/viewAddMore";

export default {
  name: "MyTask",
  components: { viewBacktop, viewAddMore },
  mixins: [en],
  data() {
    return {
      myNotice: {},
      noticeList: [], // 展示的 数据
      pageNo: 1, // 页码
      pageSize: 8, // 每页的 数量
      color: {
        "000": ["#4fd2c2", "rgba(70,148,223,0.1)"],
        "001": ["#179eda", "rgba(70,148,223,0.1)"],
        "002": ["#fd9a4a", "rgba(70,148,223,0.1)"],
        "006": ["#49bef2", "rgba(70,148,223,0.1)"],
        "004": ["#606060", "rgba(99,108,120,0.1)"],
        "003": ["#606060", "rgba(99,108,120,0.1)"],
        "007": ["#606060", "rgba(99,108,120,0.1)"],
        "008": ["#49bef2", "rgba(70,148,223,0.1)"]
      }, // 第一项是 字体颜色 第二项是 背景颜色
      billStatus: {
        "000": "未提交",
        "001": "待处理",
        "002": "审批中",
        "006": "已审批",
        "004": "已终止",
        "003": "已结束",
        "007": "已作废",
        "008": "已提交"
      },
      // 第一项是 文字 第二项是 字体颜色 第三项是 背景颜色
      planState: {
        1: ["未开始", "#49bef2", "rgba(70,148,223,0.1)"],
        2: ["已结束", "#636B77", "rgba(99,107,119,0.1)"],
        3: ["已延期", "#F76B6B", "rgba(247,107,107,0.1)"],
        4: ["逾期完成", "#FFAD2C", "gba(255,173,44,0.1)"],
        5: ["预警中", "#f88529", "rgba(99,108,120,0.1)"],
        6: ["进行中", "#636C78", "rgba(99,108,120,0.1)"]
      },
      // 是否显示无数据
      noData: false
    };
  },
  mounted() {
    this.handle();
    this.getList();
  },
  methods: {
    // 查询 我的业务数据
    async getList(flg) {
      if (flg) {
        // flg为true为加载更多不loading
      } else {
        this.loading = true;
      }
      const p = {
        pageSize: this.pageSize,
        pageNo: this.pageNo,
        status: "001"
      };
      let rspData;
      try {
        rspData = await enService.mdMyTaskList(p);
      } catch (error) {
        rspData = { records: [] };
      }
      this.myNotice = rspData;
      this.noticeList.push(...rspData.records);
      // 判断是否无数据 避免视图闪现无数据
      if (this.noticeList.length === 0) {
        this.noData = true;
      } else {
        this.noData = false;
      }
      this.loading = false;
    },
    // 点击项
    modClick(item, index) {
      const pageSwitchData = {
        pageNo: this.pageNo, // 列表请求的当前页
        pageSize: this.pageSize, // size
        selectIndex: index, // 选中的第几条数据index
        interfaceName: "mdMyTaskList", // 请求的接口名称
        params: {
          pageSize: this.pageSize,
          pageNo: this.pageNo,
          status: "001"
        }, // 该接口所需的参数
        idKey: "id", // 数据id对应的字段
        businessTypeKey: "type", // 业务类型对应的字段
        loadedData: this.noticeList // 已加载的数据
      };
      this.$router.push(
        otherEntranceRoute({
          id: item.id,
          businessType: item.type,
          templateId: item.templateId,
          templateName: item.templateName,
          pageSwitchData
        })
      );
    },
    // 任务状态
    getTaskTime(s, e, f, st) {
      //  { 1: "未开始", 2: "已完成", 3: "已延期", 4: "逾期完成", 5:"预警中", 6:"进行中" };
      let n;
      if (st === 2 || st === 4) {
        n = `${f.substring(5, 16)}完成`;
      } else if (st === 1) {
        n = `${s.substring(5, 16)}开始`;
      } else {
        n = `${e.substring(5, 16)}截止`;
      }
      return n;
    },
    // 添加任务
    addTask() {
      this.$router.push(
        newEntranceRoute({
          templateId: "180824142656336515",
          businessType: 2,
          templateName: "任务",
          viewType: this.$route.query.viewType
        })
      );
    },
    // 跳转列表
    taskList() {
      this.$router.push({ path: "/businessModel/list", query: { templateId: "180824142656336515", templateName: "任务" } });
    }
  }
};
</script>

<style lang="scss" scoped>
// 最外层
.my-enlis {
  position: relative;
  width: 100%;
  height: 100%;
  .ybox-mod {
    background: #fff;
    margin-bottom: 10px;
    border-radius: 5px;
    height: 100%;
    .card-define-head{
      padding:5px 20px;
    }
    // 标题
    .ybox-title {
      color: #333;
      font-size: 14px;
      line-height: 22px;
      zoom: 1;
      height: auto;
      // border-bottom: 1px solid #ecf1f7;
      padding: 5px 15px;
      overflow: hidden;

      .pull-left {
        float: left !important;
        font-size: 14px;
        line-height: 40px;
        // font-family: "PingFangSC-Light", "Hiragino Sans GB", Microsoft YaHei Light, Microsoft YaHei, Arial, sans-serif;
        font-weight: bold;
      }

      .pull-right {
        float: right !important;
        font-size: 14px;
        height: 40px;
        display: flex;
        align-items: center;
      }
    }

    // 内容
    .ybox-content {
      padding: 0;
      zoom: 1;
      height: calc(100% - 52px);
      .enlis {
        height: calc(100% - 46px);
        overflow-y: auto;
        font-size: 12px;
        padding-bottom: 0;
        zoom: 1;
        scroll-behavior: smooth; //平滑滚动
        padding-left: 10px;
        padding-right: 10px;
        //单个设置
        .mod:last-child {
          border-bottom: 0;
        }

        .mod {
          padding: 14px 2% 0;
          padding-left: 70px;
          line-height: 24px;
          color: #606060;
          cursor: pointer;
          zoom: 1;
          background: rgba(232, 236, 242, 0.25);
          border-radius: 4px;
          margin-bottom: 8px;
          position: relative;
          // min-height: 72px;
          min-height: 80px;
          // border-bottom: 1px solid #f3f3f3;

          .col {
            min-height: 24px;

            p {
              display: inline-block;
              margin: 0;
            }
          }

          // 图标
          .col-icon {
            min-height: 24px;
            position: absolute;
            left: 20px;
            top: 15px;
            width: 40px;

            .iconfont {
              background-color: #25c393;
              width: 40px;
              height: 40px;
              color: #fff;
              border-radius: 50%;
              line-height: 40px;
              text-align: center;
              display: block;
              margin: 0;
              font-size: 22px;
              font-style: normal;
            }
          }

          // 左侧
          .pull-left {
            float: left !important;
            text-align: left;
            width: 70%;
            .col-num {
              display: inline-block;
              vertical-align: top;
            }

            .col-tit {
            }
          }

          // 右侧
          .pull-right {
            float: right !important;
            text-align: right;
            width: 20%;
            .col-time{
              color: #91A1B7;
            }
          }
        }
        .mod:after {
          content: "";
          display: block;
          clear: both;
        }
        .mod:hover {
          // background: #F5F9FD;
          background: rgba(162, 205, 247, 0.25);
          cursor: pointer;
        }
      }
    }
  }
}
.el-dropdown-menu /deep/ .el-dropdown-menu__item:hover {
  color: #3e90fe !important;
  background: #f5f8fc !important;
}
</style>
